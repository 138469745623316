/* eslint-disable react/jsx-props-no-spreading */
import { SaveOutlined } from '@ant-design/icons';
import {
    Button, Card, Col, Form, FormInstance, notification, Row, Space, Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { parseFormValues } from '../helpers/form';
import { useApiFetch } from '../hooks/api/useApiFetch';
import { IUseModalEdit } from '../hooks/table/useModalEdit';
import FormItems from '../components/form-items';
import { IFormAction, IFormLayout, TFormItem } from '../types/layouts/form';
import Select from '../components/select';

interface IDataFormProps {
    params: IFormLayout;
    modalEdit?: IUseModalEdit;
    objectName: string;
}

export interface IFormItemProps {
    formDataLoaded: boolean;
    formRef: FormInstance<any>;
    formKey: string;
    hidden?: boolean;
    onChange?: any;
    style?: any;
}

const FormFactory = ({ params, modalEdit, objectName }: IDataFormProps) => {
    const {
        fields, title, nextAction, primaryKey, inModal, viewMode,
    } = params;

    const [dataLoading, setDataLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const api = useApiFetch();

    const { objectId } = useParams();

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const getFormData = async (id?: string|number) => {
        if (['string', 'number'].includes(typeof id)) {
            setDataLoading(true);

            const result = await api.fetchData<any>(`/${objectName}/get/${id}?copy=${!!modalEdit?.copy}`, 'get', true);

            if (result.success) {
                form.setFieldsValue(parseFormValues(result.data, fields, true));
            } else {
                notification.error({ message: result.message });
            }

            setDataLoading(false);
        } else {
            form.resetFields();
        }
        setDataLoaded(true);
    };

    const getInitialFormValues = () => {
        const initialValues: Record<string, any> = {};
        fields.forEach((field) => {
            if (typeof field?.config?.defaultValue !== 'undefined') {
                initialValues[field.name] = field.config.defaultValue;
            }
        });
        return initialValues;
    };

    useEffect(() => {
        getFormData(modalEdit?.objectId || objectId);
    }, []);

    const onActionClick = async (action: IFormAction) => {
        try {
            const values = await form.validateFields();
            onFinish(values, action.url);
        } catch (err) {
            console.log(err);
        }
    };

    const onFinish = async (values: Record<string, any>, actionUrl?: string) => {
        setSaveLoading(true);

        let apiUrl = actionUrl || `/${objectName}`;
        const isNewRow = modalEdit?.copy || (typeof objectId === 'undefined' && typeof modalEdit?.objectId === 'undefined');
        const targetId = modalEdit?.objectId || objectId;

        if (typeof actionUrl === 'undefined') {
            apiUrl += isNewRow ? `/create?copy=${modalEdit?.copy}` : `/update/${targetId}`;
        } else if (!isNewRow) {
            apiUrl += `/${targetId}`;
        }

        const body: Record<string, any> = parseFormValues(values, fields);

        const result = await api.fetchData<any>(apiUrl, 'post', true, body);

        if (result.success) {
            notification.success({ message: result.message });

            if (inModal) {
                modalEdit?.updateTableData();
                if (isNewRow) {
                    modalEdit?.hide();
                } else {
                    getFormData(targetId);
                }
            } else if (typeof nextAction === 'string') {
                const path = nextAction.replace(':primaryKey', result.data[primaryKey]);
                navigate(`/${objectName}/${path}`);
            }
        } else {
            notification.error({ message: result.message });
        }

        setSaveLoading(false);
    };

    const colProps: Record<string, number> = {
        xs: 24,
    };

    if (!inModal) {
        colProps.lg = 6;
        colProps.md = 8;
        colProps.sm = 12;
    }

    const renderFormEditor = (field: TFormItem) => {
        const Editor: React.FC<IFormItemProps> = FormItems[field.type];

        switch (field.type) {
            case 'select': {
                return (
                    <Select
                        {...field.config}
                        formRef={form}
                        formKey={field.name}
                        formDataLoaded={dataLoaded}
                    />
                );
            }
        }

        return <Editor {...field.config} formRef={form} formKey={field.name} formDataLoaded={dataLoaded} hidden={field.hidden} />;
    };

    const renderFields = () => fields?.map((field) => (
        <Col key={field.name} {...colProps}>
            <Form.Item
                label={field.title}
                required={viewMode ? false : field.required}
                rules={[{ required: field.required }]}
                name={field.name}
                hidden={field.hidden}
            >
                {renderFormEditor(field)}
            </Form.Item>
        </Col>
    ));

    const renderForm = () => (
        <fieldset disabled={viewMode}>
            <Form form={form} layout="vertical" initialValues={getInitialFormValues()} onFinish={onFinish}>
                <Row gutter={24}>
                    {renderFields()}
                </Row>
                <Form.Item hidden={viewMode}>
                    <Space direction="horizontal">
                        <Button htmlType="submit" icon={<SaveOutlined />} type="primary" loading={saveLoading}>
                            Сохранить
                        </Button>
                        {params.actions?.map((action) => (
                            <Button htmlType="button" type="primary" loading={saveLoading} onClick={() => onActionClick(action)}>
                                {action.title}
                            </Button>
                        ))}
                    </Space>
                </Form.Item>
            </Form>
        </fieldset>
    );

    return (
        <Spin spinning={dataLoading}>
            {inModal ? renderForm() : (
                <Card title={title}>
                    {renderForm()}
                </Card>
            )}
        </Spin>
    );
};

FormFactory.defaultProps = {
    modalEdit: undefined,
};

export default FormFactory;
