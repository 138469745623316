/* eslint-disable react/no-children-prop */
import { notification, Space } from 'antd';
import axios, {
    AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, CancelTokenSource,
} from 'axios';
import React from 'react';
import { config } from '../../constants/config';
import { IResponse, THttpMethods } from '../../types/api/fetch';

const httpErrorMessages: {[key: number]: string} = {
    401: 'Пользователь не авторизован',
    404: 'Запрашиваемый объект не найден',
};

axios.interceptors.response.use(undefined, (err: {response: AxiosResponse}) => {
    if ([400, 401, 404].includes(err.response.status)) {
        if (httpErrorMessages[err.response.status]) {
            err.response.data.message = err.response.data?.message || httpErrorMessages[err.response.status];
        }
        notification.error({ message: err.response.data.message });
        return err.response;
    }
    if (err.response.status === 422) {
        if (err.response.data?.errors instanceof Object) {
            const { errors } = err.response.data;
            const arr: string[] = [];
            const keys = Object.keys(errors);
            keys.forEach((key) => {
                arr.push(...errors[key]);
            });
            err.response.data.message = React.createElement(Space, {
                children: arr.map((message) => React.createElement('span', { children: message })),
                direction: 'vertical',
            });
        }
        return err.response;
    }
    return Promise.reject(err);
});

class ApiFetch {
    static USER_TOKEN_STORAGE = 'USER_TOKEN_STORAGE';

    static USER_SUB_TOKEN_STORAGE = 'USER_SUB_TOKEN_STORAGE';

    private url: string;

    private source?: CancelTokenSource;

    constructor() {
        this.url = config.apiUrl;
    }

    /**
     * Создать api-ссылку
     * @param apiMethod - api-метод
     */
    private createUrl = (apiMethod: string) => `${this.url}${apiMethod}`;

    /**
     * Получить токен авторизации из хранилища
     */
    getUserToken = (mainToken = false): string|null => {
        const token = mainToken ? localStorage.getItem(ApiFetch.USER_TOKEN_STORAGE) : localStorage.getItem(ApiFetch.USER_SUB_TOKEN_STORAGE) || localStorage.getItem(ApiFetch.USER_TOKEN_STORAGE);
        return token;
    };

    /**
     * Получить заголовки запроса
     */
    getHeaders = (useToken: boolean = false, mainToken = false): AxiosRequestHeaders => {
        const headers: AxiosRequestHeaders = {};

        if (useToken) {
            const token = this.getUserToken(mainToken);
            if (typeof token === 'string') {
                headers.Authorization = `Bearer ${token}`;
            }
        }

        return headers;
    };

    /**
     * Получить данные от сервера (описание api-методов хранятся по пути /src/services/api/connections.ts)
     * @param apiMethod - api-метод
     * @param httpMethod - http-метод ('get', 'post'), в нижнем регистре, т.к. использвуются в качестве ключа axios
     * @param useToken - требуется ли токен для api-метода
     * @param body - тело http-запроса
     * @param mainToken - использовать основной токен
     */
    async fetchData<T>(apiMethod: string, httpMethod: THttpMethods, useToken?: boolean, body?: any, mainToken?: boolean): Promise<IResponse<T>> {
        const url = this.createUrl(apiMethod);

        try {
            const headers: AxiosRequestHeaders = this.getHeaders(useToken, mainToken);

            if (useToken) {
                const token = await this.getUserToken(mainToken);

                if (typeof token !== 'string') {
                    return {
                        success: false,
                        message: 'Токена авторизации не существует',
                        data: null,
                        status: 401,
                    };
                }
            }

            const axiosConfig: AxiosRequestConfig = {
                headers,
                method: httpMethod,
                url,
                data: body,
                cancelToken: this.source?.token,
            };

            const { data, status } = await axios(axiosConfig);
            return {
                ...data,
                status,
            } as IResponse<T>;
        } catch (err) {
            return {
                success: false,
                data: err,
                message: 'Ошибка загрузки данных',
                status: 500,
            } as IResponse<T>;
        }
    }

    /**
     * Получить данные от сервера (описание api-методов хранятся по пути /src/services/api/connections.ts)
     * @param apiMethod - api-метод
     * @param httpMethod - http-метод ('get', 'post'), в нижнем регистре, т.к. использвуются в качестве ключа axios
     * @param useToken - требуется ли токен для api-метода
     * @param body - тело http-запроса
     */
    downloadFile = async (apiMethod: string, httpMethod: THttpMethods, useToken?: boolean, body?: any): Promise<IResponse<any>> => {
        try {
            const headers: AxiosRequestHeaders = this.getHeaders(useToken);

            if (useToken) {
                const token = this.getUserToken();

                if (typeof token !== 'string') {
                    return {
                        success: false,
                        message: 'Токена авторизации не существует',
                        data: null,
                        status: 401,
                    };
                }
            }

            const axiosConfig: AxiosRequestConfig = {
                headers,
                method: httpMethod,
                url: this.createUrl(apiMethod),
                data: body,
                responseType: 'blob',
            };

            const { data, status } = await axios(axiosConfig);

            if (status === 200) {
                return {
                    success: true,
                    data,
                    status,
                    message: 'Файл успешно скачен',
                };
            }
            return {
                success: false,
                data: null,
                message: 'Ошибка скачивания файла',
                status: 500,
            };
        } catch (err) {
            return {
                success: false,
                data: null,
                message: 'Ошибка скачивания файла',
                status: 500,
            };
        }
    };

    /**
     * Удалить токен авторизации из хранилища
     */
    removeUserToken = () => {
        localStorage.removeItem(ApiFetch.USER_TOKEN_STORAGE);
        localStorage.removeItem(ApiFetch.USER_SUB_TOKEN_STORAGE);
    };

    /**
     * Удалить токен авторизации из хранилища
     */
    removeSubUserToken = () => {
        localStorage.removeItem(ApiFetch.USER_SUB_TOKEN_STORAGE);
    };

    /**
     * Сохранить токен авторизации
     * @param token - токен авторизации
     */
    saveUserToken = (token: string, sub = false) => {
        localStorage.setItem(sub ? ApiFetch.USER_SUB_TOKEN_STORAGE : ApiFetch.USER_TOKEN_STORAGE, token);
    };

    /**
     * Установить токен отмены запроса
     * @param source - токен
     */
    setSource = (source: CancelTokenSource) => {
        this.source = source;
    };
}

export default ApiFetch;
