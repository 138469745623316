/* eslint-disable react/no-children-prop */
import React from 'react';
import { Space, SpaceProps, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { IPageLayoutResponse } from '../types/api/responses';
import { TPageLayoutItem } from '../types/layouts/page';
import Table from '../components/table';
import ActionButton from '../components/button';
import FormFactory from './form';
import { IUseModalEdit } from '../hooks/table/useModalEdit';
import { IUsePageObject } from '../hooks/usePageObject';
import { getLayoutItemClassName } from '../helpers/layout';
import ExportButton from '../components/export-button';
import DateRangePicker from '../components/date-range-picker';
import ChatList from '../components/chat/list';
import Chat from '../components/chat';
import TrackingMap from '../components/tracking-map';

interface ILayoutFactoryProps {
    direction?: SpaceProps['direction'],
    layout?: IPageLayoutResponse;
    modalEdit?: IUseModalEdit;
    hook?: IUsePageObject;
    objectName?: string;
}

const LayoutFactory: React.FC<React.PropsWithChildren<ILayoutFactoryProps>> = ({
    children, direction, layout, modalEdit, hook, objectName: predefinedObjectName,
}) => {
    const { objectName } = useParams();

    const isVertical = direction === 'vertical';

    const renderLayoutItem = (layoutItem: TPageLayoutItem, key: number) => {
        let element = null;

        switch (layoutItem.type) {
            case 'button': {
                element = <ActionButton params={layoutItem} modalEdit={modalEdit} hook={hook} />;
                break;
            }
            case 'list': {
                element = <Table params={layoutItem} />;
                break;
            }
            case 'form': {
                element = (
                    <FormFactory
                        params={layoutItem}
                        modalEdit={modalEdit}
                        objectName={predefinedObjectName || objectName as string}
                    />
                );
                break;
            }
            case 'title': {
                element = React.createElement(Typography.Title, {
                    className: 'mb-0',
                    ...layoutItem.config,
                });
                break;
            }
            case 'export-button': {
                element = React.createElement(ExportButton, layoutItem.config);
                break;
            }
            case 'date-range-picker': {
                // @ts-ignore
                element = React.createElement(DateRangePicker, layoutItem.config);
                break;
            }
            case 'chat-list': {
                element = React.createElement(ChatList, layoutItem);
                break;
            }
            case 'chat': {
                element = React.createElement(Chat, layoutItem);
                break;
            }
            case 'tracking-map': {
                element = React.createElement(TrackingMap, layoutItem);
                break;
            }
            case 'jsx': {
                element = layoutItem.component;
                break;
            }
        }

        if (element && React.isValidElement(element)) {
            return (
                <div key={key} className={getLayoutItemClassName(layoutItem, isVertical)}>
                    {element}
                </div>
            );
        }

        return null;
    };

    if (layout && Array.isArray(layout.elements)) {
        if (direction === 'horizontal') {
            const leftItems = layout.elements.filter((el) => el.float === 'left' || typeof el.float === 'undefined');
            const rightItems = layout.elements.filter((el) => el.float === 'right');
            return (
                <>
                    {children}
                    <Space direction={direction}>
                        {React.Children.toArray(leftItems.map(renderLayoutItem))}
                    </Space>
                    <Space direction={direction}>
                        {React.Children.toArray(rightItems.map(renderLayoutItem))}
                    </Space>
                </>
            );
        }
        return (
            <Space className="w-100" direction={direction}>
                {children}
                {React.Children.toArray(layout.elements.map(renderLayoutItem))}
            </Space>
        );
    }

    if (React.isValidElement(children)) {
        return (
            <Space className={isVertical ? 'w-100' : undefined} direction={direction}>
                {children}
            </Space>
        );
    }

    return null;
};

LayoutFactory.defaultProps = {
    direction: 'vertical',
    hook: undefined,
    layout: undefined,
    modalEdit: undefined,
    objectName: undefined,
};

export default LayoutFactory;
