import axios, { AxiosResponse } from 'axios';

interface YandexGeocoderGetAddressResponse {
    response: {
        GeoObjectCollection: {
            featureMember: {
                GeoObject: {
                    metaDataProperty: {
                        GeocoderMetaData: {
                            text: string;
                        }
                    }
                }
            }[];
        }
    }
}

class YandexGeocoder {
    public static getAddress = async (geolocation: [number, number], token: string) => {
        const result = await axios.get<any, AxiosResponse<YandexGeocoderGetAddressResponse, any>>(`https://geocode-maps.yandex.ru/1.x/?apikey=${token}&geocode=${geolocation.join(',')}&format=json`);
        return result.data;
    };
}

export default YandexGeocoder;
