import { produce } from 'immer';
import { IYandexState, TYandexActions } from '../../types/redux/yandex';
import { SET_YANDEX_MAP_TOKEN } from '../actions/yandex';

const initialState: IYandexState = {};

export const yandex = (state = initialState, action: TYandexActions): IYandexState => {
    switch (action.type) {
        case SET_YANDEX_MAP_TOKEN: {
            return produce(state, (draft) => {
                draft.token = action.payload.token;
            });
        }
        default: {
            return state;
        }
    }
};
