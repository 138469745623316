import {
    Button, Card, Space, Spin,
} from 'antd';
import { YMaps, Map } from '@pbe/react-yandex-maps';
import { useState } from 'react';
import { ClearOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import MasterPlacemark from './master-placemark';
import { ITrackingMapLayout } from '../../types/layouts/tracking-map';
import { useTrackingMapSocket } from '../../hooks/useTrackingMapSocket';
import { useTrackingMapOrders } from '../../hooks/useTrackingMapOrders';
import OrderPlacemark from './order-placemark';
import ModalEdit from '../modal-edit';
import { useGeolocationAddress } from '../../hooks/useGeolocationAddress';
import { yandexMapTokenSelector } from '../../redux/selectors/yandex';

function TrackingMap({ channel, title }: ITrackingMapLayout) {
    const { trackingObjects } = useTrackingMapSocket(channel);
    const { orders, modal } = useTrackingMapOrders();
    const [showOnly, setShowOnly] = useState<'masters'|'orders'>();
    const [showOnlyFreeMasters, setShowOnlyFreeMasters] = useState(false);
    const map = useGeolocationAddress();
    const token = useSelector(yandexMapTokenSelector);

    const titleEl = (
        <Space direction="horizontal">
            <h5 className="ant-typography mb-0">
                {title}
            </h5>
            <Button disabled={showOnly === 'orders'} type="link" onClick={() => setShowOnly('orders')}>
                Заказы:
                {' '}
                {orders.length}
            </Button>
            <Button disabled={showOnly === 'masters'} type="link" onClick={() => setShowOnly('masters')}>
                Мастера:
                {' '}
                {trackingObjects.length}
            </Button>
            <Button type="link" onClick={() => setShowOnlyFreeMasters(!showOnlyFreeMasters)}>
                Свободен:
                {' '}
                {trackingObjects.filter((el) => !!el.free).length}
                {showOnlyFreeMasters && <ClearOutlined />}
            </Button>
            {typeof showOnly === 'string' && (
                <Button type="link" onClick={() => setShowOnly(undefined)}>
                    Показать все
                </Button>
            )}
        </Space>
    );

    let masters = trackingObjects;

    if (showOnlyFreeMasters) {
        masters = masters.filter((el) => el.free);
    }

    if (typeof token === 'string') {
        return (
            <Spin spinning={map.loading}>
                <Card title={titleEl}>
                    {map.loaded && (
                        <Space direction="vertical" style={{ width: '100%' }} size="middle">
                            <YMaps query={{ apikey: token }}>
                                <Map defaultState={{ center: map.center, zoom: 9 }} width="100%" height="calc(100vh - 205px)">
                                    {(showOnly === 'masters' || typeof showOnly === 'undefined') && masters.map((obj, index) => (
                                        <MasterPlacemark
                                            key={index}
                                            data={obj}
                                            geometry={[obj.geolocation.latitude, obj.geolocation.longitude]}
                                        />
                                    ))}
                                    {(showOnly === 'orders' || typeof showOnly === 'undefined') && orders.map((order) => (
                                        <OrderPlacemark
                                            key={order.id}
                                            order={order}
                                            modal={modal}
                                        />
                                    ))}
                                </Map>
                            </YMaps>
                        </Space>
                    )}
                </Card>
                <ModalEdit hook={modal} objectName="orders" />
            </Spin>
        );
    }

    return null;
}

export default TrackingMap;
