import { useEffect, useState } from 'react';
import { useApiFetch } from './api/useApiFetch';
import { IUserInfo } from '../types/api/user';
import ApiFetch from '../services/api/fetch';

export const useSubUser = () => {
    const api = useApiFetch();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState<IUserInfo>();

    const getData = async () => {
        setLoading(true);

        const response = await api.fetchData<IUserInfo>('/user', 'get', true, undefined);

        if (response.success && response.data instanceof Object) {
            setInfo(response.data);
        }

        setLoading(false);
    };

    const logout = () => {
        (new ApiFetch()).removeSubUserToken();
        window.location.href = '/';
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        info,
        loading,
        logout,
    };
};
