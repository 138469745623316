import { combineReducers } from 'redux';
import { IRootState } from '../../types/redux/root';
import { tables } from './tables';
import { layouts } from './layouts';
import { yandex } from './yandex';

export default combineReducers<IRootState>({
    tables,
    layouts,
    yandex,
});
