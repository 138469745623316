import { DatePicker } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { produce } from 'immer';
import moment, { Moment } from 'moment';
import { IDateRangePickerLayout } from '../../types/layouts/date-range-picker';
import { tableStateSelector } from '../../redux/selectors/tables';
import { appendFilter, setFilters } from '../../redux/actions/tables';

const defaultFormat = 'DD.MM.YYYY';
const apiFormat = 'YYYY-MM-DD';

const DateRangePicker = (props: IDateRangePickerLayout['config']) => {
    const { action, format: propFormat } = props;
    const dispatch = useDispatch();
    const tableState = useSelector(tableStateSelector(action?.actionTarget.name));
    const format = typeof propFormat === 'string' ? propFormat : defaultFormat;

    const getValue = (): any => {
        if (action?.actionTarget.type === 'list' && tableState instanceof Object) {
            return tableState.filters?.find((el) => el.column === action.actionTarget.column)?.values.map((value: any) => moment(value, apiFormat));
        }
        return undefined;
    };

    const onChange = (values: any) => {
        if (action?.actionTarget.type === 'list') {
            if (Array.isArray(values) && values.length === 2) {
                dispatch(appendFilter(action?.actionTarget.name, {
                    column: action.actionTarget.column,
                    values: values.map((value: Moment) => value.format(apiFormat)),
                    operator: 'between',
                }));
            } else {
                const index = tableState?.filters?.findIndex((el) => el.column === action.actionTarget.column);
                const nextFilters = produce(tableState?.filters || [], (draft) => {
                    if (typeof index === 'number') {
                        draft?.splice(index, 1);
                    }
                });
                dispatch(setFilters(action.actionTarget.name, nextFilters));
            }
        }
    };

    return React.createElement(DatePicker.RangePicker, {
        ...props,
        format: format || defaultFormat,
        onChange,
        value: getValue(),
    });
};

export default DateRangePicker;
