import React, { useEffect, useState } from 'react';
import { Select as SelectAntd } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useApiFetch } from '../../hooks/api/useApiFetch';
import { IListResponse } from '../../types/api/responses';
import { ISelectLayout } from '../../types/layouts/select';
import { IFormItemProps } from '../../factories/form';

const Select: React.FC<IFormItemProps & ISelectLayout['config'] & { value?: any }> = ({
    allowClear, defaultValue, displayKey, formDataLoaded, listApi,
    valueKey, additionalBody, style, value, multiple, onChange, options,
}) => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<Record<string, any>[]>(options ?? []);

    const { fetchData } = useApiFetch();

    const filterOption = (input: string, option?: DefaultOptionType): boolean => {
        if (option instanceof Object && (typeof option.label === 'string' || typeof option.label === 'number')) {
            return option.label.toString().toLowerCase().includes(input.toLowerCase());
        }
        return false;
    };

    const getList = async () => {
        if (typeof listApi === 'string') {
            setLoading(true);

            const result = await fetchData<IListResponse>(listApi, 'post', true, {
                count: 1000,
                ...additionalBody,
            });

            if (result.success && result.data) {
                const { list: selectList } = result.data;
                if (Array.isArray(selectList)) {
                    setList(selectList);
                }
            }

            setLoading(false);
        }
    };

    const getOptions = (items: typeof list): DefaultOptionType[] => items.map((item) => {
        if (Array.isArray(item.children)) {
            return {
                label: item[displayKey || 'label'],
                options: getOptions(item.children),
            };
        }
        return {
            className: item.hidden ? 'd-none' : undefined,
            label: item[displayKey || 'label'],
            value: item[valueKey || 'value'],
        };
    });

    const onChangeFn = (val: any) => {
        if (list.length > 0) {
            const nextValue = typeof val === 'undefined' && allowClear ? defaultValue : val;
            if (typeof onChange === 'function') {
                onChange(nextValue);
            }
        }
    };

    useEffect(() => {
        if (formDataLoaded) {
            getList();
        }
    }, [listApi, formDataLoaded]);

    return (
        <SelectAntd
            mode={multiple ? 'multiple' : undefined}
            disabled={loading}
            loading={loading}
            showArrow
            showSearch
            value={loading ? undefined : value}
            style={style}
            filterOption={filterOption}
            allowClear={allowClear}
            options={getOptions(list)}
            onChange={onChangeFn}
        />
    );
};

Select.defaultProps = {
    value: undefined,
};

export default Select;
