/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';
import {
    Card, Table as AntTable, TableProps, Input,
} from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import ModalEdit from '../modal-edit';
import { usePageObject } from '../../hooks/usePageObject';
import Cell from './cell';
import { ITableColumn, ITableLayout } from '../../types/layouts/table';
import LayoutFactory from '../../factories/layout';
import { useTable } from '../../hooks/table/useTable';
import { useColumns } from '../../hooks/table/useColumns';
import { getRowColor } from '../../helpers/table';
import { IJsxLayout } from '../../types/layouts/jsx';

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => React.createElement('tr', props));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => React.createElement('tbody', props));

interface IDataTableProps {
    params: ITableLayout,
}

const Table = ({ params }: IDataTableProps) => {
    const table = useTable(params);
    const pageObjectHook = usePageObject();

    const {
        ds, modal, selectedRows, setSelectedRows,
    } = table;

    const columns = useColumns(table);

    const {
        currentPage, dataSource, loading, total,
        updateDataSource, onSortersChange, onPageChange,
    } = ds;

    const cardExtraElements = useMemo(() => (params.cardExtra?.elements || []).map((el: any) => {
        // @ts-ignore
        if (el.type === 'table-search') {
            return {
                ...el,
                type: 'jsx',
                component: (
                    <div className="table-search-input-container">
                        <Input.Search
                            allowClear
                            className="table-search-input"
                            defaultValue={ds.search}
                            onSearch={ds.onSearch}
                            placeholder="Поиск..."
                        />
                    </div>
                ),
            } as IJsxLayout;
        }
        return el;
    }), []);

    const extra = (
        <div className="card-extra-content">
            <div className="card-extra-layout-content">
                <LayoutFactory
                    direction="horizontal"
                    layout={{
                        ...params.cardExtra,
                        elements: [
                            ...cardExtraElements,
                            ...(ds.cardExtra?.elements || []),
                        ],
                    }}
                    modalEdit={modal}
                    hook={pageObjectHook}
                />
            </div>
        </div>
    );

    const onChange: TableProps<any>['onChange'] = (_, tableFilters, tableSorting) => {
        if (!Array.isArray(tableSorting) && typeof tableSorting.columnKey === 'string') {
            onSortersChange([{
                column: tableSorting.columnKey,
                desc: tableSorting.order === 'descend',
            }]);
        }
    };

    const onRow = (record: any) => {
        if (Array.isArray(params.rowColors)) {
            const colors = getRowColor(params.rowColors, record);

            if (colors instanceof Object) {
                return {
                    style: {
                        backgroundColor: colors.backgroundColor,
                        color: colors.textColor,
                    },
                };
            }
        }
        return {};
    };

    useEffect(() => {
        if (modal.shouldUpdateTableData || pageObjectHook.shouldUpdateContent) {
            updateDataSource();
        }
    }, [modal.shouldUpdateTableData, pageObjectHook.shouldUpdateContent]);

    const onHeaderRow = () => {
        const headerCells = document.querySelectorAll('th.ant-table-cell');
        headerCells.forEach((hc: Element) => {
            if (typeof params.headerColor?.backgroundColor === 'string') {
                // @ts-ignore
                hc.style.setProperty('background-color', params.headerColor?.backgroundColor, 'important');
            } else {
                // @ts-ignore
                hc.style.removeProperty('background-color');
            }
            if (typeof params.headerColor?.textColor === 'string') {
                // @ts-ignore
                hc.style.setProperty('color', params.headerColor?.textColor, 'important');
            } else {
                // @ts-ignore
                hc.style.removeProperty('color');
            }
        });
        return {};
    };

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el: any) => !!el,
            );
            ds.onDNDropSortChange(newData);
        }
    };

    const DraggableContainer = (props: SortableContainerProps) => React.createElement(SortableBody, {
        ...props,
        useDragHandle: true,
        disableAutoscroll: true,
        helperClass: 'row-dragging',
        onSortEnd,
    });

    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        const { sortingByDnD } = params;
        if (typeof sortingByDnD === 'string') {
            const index = dataSource.findIndex((x) => x[params.primaryKey] === restProps['data-row-key']);

            return React.createElement(SortableItem, {
                index,
                ...restProps,
            });
        }
        return null;
    };

    const getSummaryStyle = (record: any) => {
        if (Array.isArray(params.rowColors)) {
            const colors = getRowColor(params.rowColors, record);

            if (colors instanceof Object) {
                return {
                    backgroundColor: colors.backgroundColor,
                    color: colors.textColor,
                };
            }
        }
        return {};
    };

    const renderSummaryCell = (column: ITableColumn, obj: any) => {
        if (typeof column.dataIndex === 'string' && obj instanceof Object) {
            const value = obj[column.dataIndex];
            if (typeof column.html === 'string') {
                return React.createElement('div', {
                    dangerouslySetInnerHTML: {
                        __html: column.html.replace('{value}', value),
                    },
                });
            }
            return value;
        }
        return '-';
    };

    const getSummary = () => {
        if (ds.summary instanceof Object) {
            return (
                <AntTable.Summary fixed={ds.summary?.fixed}>
                    {ds.summary.data.map((obj, i) => (
                        <AntTable.Summary.Row key={i} style={getSummaryStyle(obj)}>
                            {columns.map((column, j) => (
                                <AntTable.Summary.Cell
                                    {...column}
                                    key={j}
                                    index={j}
                                    align={column.align}
                                >
                                    {renderSummaryCell(column, obj)}
                                </AntTable.Summary.Cell>

                            ))}
                        </AntTable.Summary.Row>
                    ))}
                </AntTable.Summary>
            );
        }
        return undefined;
    };

    const getY = () => {
        const heights = [110];

        heights.push(document.querySelector('.ant-layout-header')?.clientHeight || 0);
        heights.push(document.querySelector('.ant-table-summary')?.clientHeight || 0);
        heights.push(document.querySelector('.ant-card-head')?.clientHeight || 0);
        heights.push(document.querySelector('.ant-table-pagination')?.clientHeight || 0);
        heights.push(document.querySelector('.ant-table-thead')?.clientHeight || 0);

        return `calc(100vh - ${heights.join('px - ')}px)`;
    };

    return (
        <>
            <Card title={params.title} extra={extra}>
                <AntTable
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={params.primaryKey}
                    rowSelection={typeof params.rowSelectionType === 'string' ? {
                        type: params.rowSelectionType,
                        onChange: (_, rows) => setSelectedRows(rows),
                        selectedRowKeys: selectedRows?.map((el: any) => el[params.primaryKey]),
                    } : undefined}
                    loading={loading}
                    pagination={params.disablePagination ? false : {
                        current: currentPage,
                        pageSize: ds.pageSize,
                        total,
                        onChange: onPageChange,
                    }}
                    size="small"
                    scroll={{
                        y: params.fullPageSize ? getY() : undefined,
                        ...params.scroll,
                        ...(ds.tableParams?.scroll),
                    }}
                    onChange={onChange}
                    onRow={onRow}
                    components={{
                        body: {
                            cell: Cell,
                            wrapper: typeof params.sortingByDnD === 'string' ? DraggableContainer : undefined,
                            row: typeof params.sortingByDnD === 'string' ? DraggableBodyRow : undefined,
                        },
                    }}
                    summary={getSummary}
                    onHeaderRow={onHeaderRow}
                />
            </Card>
            {params.editType === 'modal' && (
                <ModalEdit hook={modal} />
            )}
        </>
    );
};

export default Table;
