import { useEffect, useState } from 'react';
import { useApiFetch } from './api/useApiFetch';
import PusherService from '../services/pusher';

export interface ITrackingMessageData {
    master_id: number;
    name: string;
    geolocation: {
        latitude: number;
        longitude: number;
    };
    logisticianStatus?: {
        color: string;
        created_at: string;
        id: number;
        name: string;
        text_color: string | null;
        updated_at: string;
    };
    specializes_in: string | null;
    commentary: string;
    free: boolean;
    batteryLvl: string | null;
}

export const useTrackingMapSocket = (channel: string) => {
    const [trackingObjects, setTrackingObjects] = useState<ITrackingMessageData[]>([]);

    const { fetchData } = useApiFetch();

    const onSocketOpen = () => {
        fetchData('/tracking-map/get', 'get', true);
    };

    const openSocket = () => {
        const conn = new PusherService();
        conn.registerMessageListener('tracking', onTrackingMessage);
        conn.registerOpenListener(onSocketOpen);
        conn.open(channel);
    };

    const onTrackingMessage = (data: ITrackingMessageData[]) => {
        setTrackingObjects(Array.isArray(data) ? data : []);
    };

    useEffect(() => {
        openSocket();
    }, []);

    return {
        trackingObjects,
    };
};
